import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import IWDropdown, {
  DropdownValueProps,
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import IWRelativeDatePickerWrapper from 'shared/components/thunderbolt/IWRelativeDatePickerWrapper'
import IWSpacer from 'shared/components/thunderbolt/IWSpacer'
import {
  getClearedBidsFilters,
  getClientClearedBids,
} from 'shared/loadSchedulingClient'
import styled from 'styled-components'
import LoadSchedulingTable, { EmptyMessage } from '../components/LSTable'
import { useClearedBidsFilters } from '../hooks/useClearedBidsFilters'
import { getLSClearedBidsTableSchema } from '../schemas'

const StyledWrapper = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`

const StyledFilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`

const USE_QUERY_KEY = 'ls-cleared-bids'

export default function ClearedBidsHistory() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [tableItems, setTableItems] = useState<unknown[]>([])

  const tableColumns = getLSClearedBidsTableSchema()

  const {
    marketOptions,
    brandOptions,
    zoneOptions,
    locationOptions,
    locationNameOptions,
    submitTypeOptions,
    datePickerState,
    selectedMarket,
    selectedBrand,
    selectedZone,
    selectedLocation,
    selectedLocationName,
    selectedSubmitType,
    isDatePickerOpen,
    setDatePickerIsOpen,
    setFilterOptions,
    handleOnSelect,
    handleDatePickerCancel,
    handleDatePickerConfirm,
  } = useClearedBidsFilters()

  const { data: clearedBids, isLoading } = useQuery({
    queryKey: [
      USE_QUERY_KEY,
      selectedMarket,
      selectedBrand,
      selectedZone,
      selectedLocationName,
      selectedLocation,
      selectedSubmitType,
      datePickerState.selectedDatePickerMin,
      datePickerState.selectedDatePickerMax,
    ],
    queryFn: async () => {
      const startDate = datePickerState.selectedDatePickerMin?.toISODate()
      const endDate = datePickerState.selectedDatePickerMax?.toISODate()

      return getClientClearedBids({
        startDate: startDate!,
        endDate: endDate!,
        market: selectedMarket?.value,
        brand: selectedBrand?.value,
        zone: selectedZone?.value,
        locationName: selectedLocationName?.value,
        location: selectedLocation?.value,
        submitType: selectedSubmitType?.value,
      })
    },
  })

  const {
    data: filters,
    isLoading: isFiltersLoading,
    isFetched: isFiltersFetched,
  } = useQuery({
    queryKey: [USE_QUERY_KEY + 'FILTERS'],
    queryFn: async () => getClearedBidsFilters(),
  })

  const tableMapper = () => {
    const items = (clearedBids || []).map((bid) => {
      const intervals = bid.clearedBids.reduce((memo, current) => {
        memo[current.interval] = current.load
        return memo
      }, {})

      return {
        clearedBidId: bid.clearedBidId,
        date: bid.date,
        market: bid.market,
        brand: bid.brand,
        zone: bid.zone,
        location: bid.location,
        locationName: bid.locationName,
        submitType: bid.submitType,
        ...intervals,
      }
    })

    setTableItems(orderBy(items, ['date']) as unknown[])
  }

  const onDropdownSelect = (
    key: string,
    value: GenericDropdownValue<string | undefined>,
  ) => {
    handleOnSelect<string | undefined>(key, value)
  }

  useEffect(() => {
    if (!isFiltersLoading) {
      tableMapper()
    }
  }, [clearedBids])

  useEffect(() => {
    if (filters && isFiltersFetched) {
      setFilterOptions({
        ...filters,
        submitType: ['EnergyBid', 'EnergyOnlyOffer', 'DEMAND_BID'],
      })
    }
  }, [filters])

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [USE_QUERY_KEY] })
  }, [
    datePickerState.selectedDatePickerMin,
    datePickerState.selectedDatePickerMax,
  ])

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <StyledWrapper>
          <StyledTitle>
            {t('loadScheduling.clearedBidsHistoryPageTitle')}
          </StyledTitle>
          <IWSpacer />
        </StyledWrapper>
      </IWPageHeader>

      <main>
        {isFiltersFetched && (
          <StyledFilterWrapper>
            <IWRelativeDatePickerWrapper
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...datePickerState}
              label={t('loadScheduling.submissionHistoryOptions.labels.date')}
              maxTimeLengthValue={100}
              isOpen={isDatePickerOpen}
              onPlaceholderClick={() => setDatePickerIsOpen(true)}
              onConfirm={() => {
                handleDatePickerConfirm()
              }}
              onCancel={handleDatePickerCancel}
            />

            <IWDropdown
              fullWidth
              label={t('loadScheduling.submissionHistoryOptions.labels.market')}
              options={marketOptions}
              value={selectedMarket}
              onChange={(value: DropdownValueProps) => {
                onDropdownSelect('market', value)
              }}
            />

            <IWDropdown
              fullWidth
              label={t('loadScheduling.submissionHistoryOptions.labels.brand')}
              options={brandOptions}
              value={selectedBrand}
              onChange={(value: DropdownValueProps) => {
                onDropdownSelect('brand', value)
              }}
            />

            <IWDropdown
              fullWidth
              label={t('loadScheduling.submissionHistoryOptions.labels.zone')}
              options={zoneOptions}
              value={selectedZone}
              onChange={(value: DropdownValueProps) => {
                onDropdownSelect('zone', value)
              }}
            />

            <IWDropdown
              fullWidth
              label={t(
                'loadScheduling.submissionHistoryOptions.labels.locationName',
              )}
              options={locationNameOptions}
              value={selectedLocationName}
              onChange={(value: DropdownValueProps) => {
                onDropdownSelect('locationName', value)
              }}
            />

            <IWDropdown
              fullWidth
              label={t(
                'loadScheduling.submissionHistoryOptions.labels.location',
              )}
              options={locationOptions}
              value={selectedLocation}
              onChange={(value: DropdownValueProps) => {
                onDropdownSelect('location', value)
              }}
            />

            <IWDropdown
              fullWidth
              label={t(
                'loadScheduling.submissionHistoryOptions.labels.submitType',
              )}
              options={submitTypeOptions}
              value={selectedSubmitType}
              onChange={(value: DropdownValueProps) => {
                onDropdownSelect('submitType', value)
              }}
            />
          </StyledFilterWrapper>
        )}

        {isLoading && <IWLoading />}
        {!isLoading && !Boolean(tableItems.length) && <EmptyMessage />}
        {!isLoading && Boolean(tableItems.length) && (
          <LoadSchedulingTable
            columns={tableColumns}
            items={tableItems}
            isLoading={false}
          />
        )}
      </main>
    </>
  )
}
