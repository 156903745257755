import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'react'
import { GenericDropdownValue } from 'shared/components/thunderbolt/IWDropdown'
import { MainOptions } from 'shared/components/thunderbolt/IWRelativeDatePicker'
import LayoutContext from 'shared/contexts/LayoutContext'
import useIWRelativeDatePicker, {
  defaultHasErrorsCalculation,
  defaultIndividualErrorsCalculation,
  type DefaultState,
} from 'shared/hooks/useIWRelativeDatePicker'

const datePickerDisabledMainOptions: MainOptions[] = [
  'after',
  'before',
  'previous',
  'next',
  'on',
]

export const useClearedBidsFilters = () => {
  const { timezone } = useContext(LayoutContext)

  const [isDatePickerOpen, setDatePickerIsOpen] = useState(false)

  const [marketOptions, setMarketOptions] = useState<
    GenericDropdownValue<string | undefined>[]
  >([])
  const [brandOptions, setBrandOptions] = useState<
    GenericDropdownValue<string | undefined>[]
  >([])
  const [zoneOptions, setZoneOptions] = useState<
    GenericDropdownValue<string | undefined>[]
  >([])
  const [locationOptions, setLocationOptions] = useState<
    GenericDropdownValue<string | undefined>[]
  >([])
  const [locationNameOptions, setLocationNameOptions] = useState<
    GenericDropdownValue<string | undefined>[]
  >([])
  const [submitTypeOptions, setSubmitTypeOptions] = useState<
    GenericDropdownValue<'EnergyBid' | 'EnergyOnlyOffer' | undefined>[]
  >([])

  const [datePickerDefaults, setDatePickerDefaults] = useState<DefaultState>({
    selectedMainOption: { value: 'between' },
    selectedTimeLengthOption: { value: 'days' },
    selectedRelativeOption: { value: 'today' },
    selectedSpecificDatePickerDate: DateTime.local({ zone: timezone }),
    selectedDatePickerMin: DateTime.local({ zone: timezone }).minus({ day: 1 }),
    selectedDatePickerMax: DateTime.local({ zone: timezone }),
    timeLengthValue: 30,
    disabledMainOptions: datePickerDisabledMainOptions,
  })

  const [selectedMarket, setSelectedMarket] =
    useState<GenericDropdownValue<string | undefined>>()
  const [selectedBrand, setSelectedBrand] =
    useState<GenericDropdownValue<string | undefined>>()
  const [selectedZone, setSelectedZone] =
    useState<GenericDropdownValue<string | undefined>>()
  const [selectedLocation, setSelectedLocation] =
    useState<GenericDropdownValue<string | undefined>>()
  const [selectedLocationName, setSelectedLocationName] =
    useState<GenericDropdownValue<string | undefined>>()
  const [selectedSubmitType, setSelectedSubmitType] =
    useState<GenericDropdownValue<string | undefined>>()

  const datePickerState = useIWRelativeDatePicker({
    ...datePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const handleOnSelect = <T>(key: string, value: GenericDropdownValue<T>) => {
    const updatedValue = value as GenericDropdownValue<string | undefined>

    if (key === 'market') {
      setSelectedMarket(updatedValue)
    }
    if (key === 'brand') {
      setSelectedBrand(updatedValue)
    }
    if (key === 'zone') {
      setSelectedZone(updatedValue)
    }
    if (key === 'locationName') {
      setSelectedLocationName(updatedValue)
    }
    if (key === 'location') {
      setSelectedLocation(updatedValue)
    }
    if (key === 'submitType') {
      setSelectedSubmitType(updatedValue)
    }
  }

  const setFilterOptions = (filters: Record<string, string[]>) => {
    const allFilterOptions = { label: 'All', value: undefined }

    setMarketOptions([
      allFilterOptions,
      ...filters.market.sort().map((v) => ({ label: v, value: v })),
    ])
    setBrandOptions([
      allFilterOptions,
      ...filters.brand.sort().map((v) => ({ label: v, value: v })),
    ])
    setZoneOptions([
      allFilterOptions,
      ...filters.zone.sort().map((v) => ({ label: v, value: v })),
    ])
    setLocationOptions([
      allFilterOptions,
      ...filters.location.sort().map((v) => ({ label: v, value: v })),
    ])
    setLocationNameOptions([
      allFilterOptions,
      ...filters.locationName.sort().map((v) => ({ label: v, value: v })),
    ])
    setSubmitTypeOptions([
      allFilterOptions,
      // @ts-ignore
      ...filters.submitType.sort().map((v) => ({
        label: v,
        value: v,
      })),
    ])
  }

  const handleDatePickerCancel = () => {
    datePickerState.modifyFullState(datePickerDefaults)
    setDatePickerIsOpen(false)
  }

  const handleDatePickerConfirm = () => {
    setDatePickerDefaults({
      selectedMainOption: datePickerState.selectedMainOption,
      selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
      selectedRelativeOption: datePickerState.selectedRelativeOption,
      selectedSpecificDatePickerDate:
        datePickerState.selectedSpecificDatePickerDate,
      selectedDatePickerMin: datePickerState.selectedDatePickerMin,
      selectedDatePickerMax: datePickerState.selectedDatePickerMax,
      timeLengthValue: datePickerState.timeLengthValue,
      disabledMainOptions: datePickerDisabledMainOptions,
    })
    setDatePickerIsOpen(false)
  }

  useEffect(() => {
    if (marketOptions.length)
      setSelectedMarket(selectedMarket || marketOptions[0])

    if (brandOptions.length) setSelectedBrand(selectedBrand || brandOptions[0])

    if (zoneOptions.length) setSelectedZone(selectedZone || zoneOptions[0])

    if (locationOptions.length)
      setSelectedLocation(selectedLocation || locationOptions[0])

    if (locationNameOptions.length)
      setSelectedLocationName(selectedLocation || locationNameOptions[0])

    if (submitTypeOptions.length)
      setSelectedSubmitType(selectedSubmitType || submitTypeOptions[0])
  }, [
    marketOptions,
    brandOptions,
    locationOptions,
    locationNameOptions,
    zoneOptions,
    submitTypeOptions,
  ])

  return {
    marketOptions,
    brandOptions,
    zoneOptions,
    locationOptions,
    locationNameOptions,
    submitTypeOptions,
    datePickerState,
    setMarketOptions,
    setBrandOptions,
    setZoneOptions,
    setLocationOptions,
    setLocationNameOptions,
    setSubmitTypeOptions,
    setDatePickerDefaults,
    selectedMarket,
    selectedBrand,
    selectedZone,
    selectedLocation,
    selectedLocationName,
    selectedSubmitType,
    handleOnSelect,
    setFilterOptions,
    isDatePickerOpen,
    setDatePickerIsOpen,
    handleDatePickerCancel,
    handleDatePickerConfirm,
  }
}
