import type { AxiosResponse } from 'axios'
import { groupBy } from 'lodash'
import { DateTime } from 'luxon'
import { number } from 'mathjs'
import { CreateOrgCredential } from 'systemPreferences/components/LoadScheduling/CreateOrgCredentialModal'
import { UpdateOrgCredential } from 'systemPreferences/components/LoadScheduling/UpdateOrgCredentialModal'
import type { LSSubmissionSummary } from 'tools/loadScheduling/components/LSSubmissionList'
import type { ErcotLSSubmissionData } from 'tools/loadScheduling/helpers'
import type {
  DayAheadDemandBid,
  ExpandedTradesPodData,
  InternalBilateral,
  InternalBilateralContract,
  SubmissionHistory,
} from '../tools/loadScheduling/types'
import axiosApiClient from './apiClient'
import { getDatasourceMetadata, getRawData } from './podServiceClient'

export type LocationAttribute = {
  market: string
  brand: string
  zone: string
  utility: string
  location: string
  locationName: string
  configId: string
  createdAt: string
  createdBy: string
  credentialId: string
  locationAttributeId: string
  isCredentialActive?: boolean
  updatedAt: string
  updatedBy: string
}

export type TargetNetOpenPositions = {
  nopId: string
  org: string
  market: string
  brand: string
  location: string
  bid: number
  offer: number
}

export type PriceInfo = {
  priceInfoId: string
  org: string
  market: string
  brand: string
  location: string
  intervalStart: number
  intervalEnd: number
  bidPrice: number
  offerPrice: number
}

type SubmittableDates = Record<
  string,
  { startDate: string; endDates: string; dates: string[] }
>

type RoundingItem = {
  org: string
  bid: string
  offer: string
}

type RoundingItemResult = {
  org: string
  bid: number
  offer: number
}

export type OrgConfig = {
  market: string
  brand: string
  configId: string
  isActive: boolean
  org: string
}

export type OrgCredential = {
  credentialId: string
  configId: string
  org: string
  brand: string
  zone: string
  market: string
  location: string
  locationName: string
  userId: string
  userAccount: string | null
  userPassword: string | null
  userSubAccount: string
  pfx: string
  pfxPassphrase: string
  isActive: boolean
  isSuma: boolean
  useCertificate: boolean
  createdBy: string
  updatedBy: string
  createdAt: string
  updatedAt: string
}

export type ClearedBidDTO = {
  market: string
  org: string
  zone: string
  subAccount: string
  brand: string
  location: string
  locationName: string
  submitType: 'EnergyBid' | 'EnergyOnlyOffer' | 'DEMAND_BID'
  clearedBids: {
    interval: number
    load: number
    price: number
  }[]
  date: string
  clearedBidId: string
  createdAt: string
  updatedAt: string
}

const BASE_URL = '/lsm/v1'

export async function convertToISOFormat(
  dayAheadBids: DayAheadDemandBid[] = [],
  nyisoUserId = '',
  nyisoUserPassword = '',
): Promise<
  {
    fileName: string
    fileExtension: 'xml' | 'txt'
    fileContent: string
    size: number
  }[]
> {
  const groupedByMarket = groupBy(dayAheadBids, 'market')
  const result = await Promise.all(
    Object.entries(groupedByMarket).map(async (element) => {
      const [market, value] = element
      const body = value.map((v) => ({
        brand: v.brand,
        date: v.date,
        location: v.location,
        intervals: v.intervals,
      }))

      const { data: response } = await axiosApiClient.post(
        `${BASE_URL}/day-ahead/demand-bids/${market}/convert`,
        {
          demandBids: body,
          userId: nyisoUserId,
          userPassword: nyisoUserPassword,
        },
      )

      return response.map(
        (v: {
          market: string
          brand: string
          location: string
          fileName: string
          fileExtension: 'xml' | 'txt'
          fileContent: string
          amountOfDates: number
        }) => ({
          market: v.market,
          brand: v.brand,
          location: v.location,
          fileName: v.fileName,
          fileExtension: v.fileExtension,
          fileContent: v.fileContent,
          size: v.amountOfDates,
        }),
      )
    }),
  )
  return result.flat()
}

export async function convertIbtToISOFormat(
  internalBilaterals: InternalBilateral[] = [],
): Promise<
  {
    fileName: string
    fileExtension: 'xml' | 'txt'
    fileContent: string
    size: number
  }[]
> {
  const groupedByMarket = groupBy(internalBilaterals, 'market')
  const result = await Promise.all(
    Object.entries(groupedByMarket).map(async (element) => {
      const [, value] = element
      const [first] = value
      const body = value.map((v) => ({
        date: v.date,
        intervals: v.intervals,
      }))

      // FIXME: make market dynamic, this is just for development
      const { data: response } = await axiosApiClient.post(
        `${BASE_URL}/day-ahead/physical/pjm/convert/${first.contract_id}`,
        body,
      )
      return response.status === 'success' && response.data?.length
        ? response.data
        : []
    }),
  )
  return result.flat()
}

export async function getConfigIds(
  includeInactive: boolean = false,
): Promise<OrgConfig[]> {
  const url = `${BASE_URL}/org/configs?includeInactive=${includeInactive}`
  const { data: response } = await axiosApiClient.get(url)
  return response || []
}

export async function createOrgConfig(
  config: Omit<OrgConfig, 'configId' | 'isActive'>,
): Promise<OrgConfig[]> {
  const url = `${BASE_URL}/org/configs`
  const { data: response } = await axiosApiClient.post(url, config)
  return response || []
}

export async function deleteOrgConfig(configId: string): Promise<OrgConfig[]> {
  const url = `${BASE_URL}/org/configs/${configId}`
  const { data: response } = await axiosApiClient.delete(url)
  return response || []
}

export async function toggleActiveOrgConfig(
  configId: string,
  isActive: boolean,
) {
  const url = `${BASE_URL}/org/configs/${configId}/${
    isActive ? 'activate' : 'deactivate'
  }`

  return axiosApiClient.patch(url)
}

export async function getCredentials(
  includeInactive: boolean = false,
): Promise<OrgCredential[]> {
  const url = `${BASE_URL}/org/credentials?includeInactive=${includeInactive}`
  const { data: response } = await axiosApiClient.get(url)
  return response || []
}

export async function toggleActiveOrgCredential(
  credentialId: string,
  isActive: boolean,
) {
  const url = `${BASE_URL}/org/credentials/${credentialId}/${
    isActive ? 'activate' : 'deactivate'
  }`

  return axiosApiClient.patch(url)
}

export async function createOrgCredential(
  credential: CreateOrgCredential,
): Promise<OrgConfig[]> {
  const { configId, ...payload } = credential
  const url = `${BASE_URL}/org/credentials/${configId}`
  const { data: response } = await axiosApiClient.post(url, payload)
  return response || []
}

export async function updateOrgCredential(
  credential: UpdateOrgCredential,
): Promise<OrgConfig[]> {
  const { credentialId, ...payload } = credential
  const url = `${BASE_URL}/org/credentials/${credentialId}`
  const { data: response } = await axiosApiClient.patch(url, payload)
  return response || []
}

export async function deleteOrgCredential(
  credentialId: string,
): Promise<OrgCredential[]> {
  const url = `${BASE_URL}/org/credentials/${credentialId}`
  const { data: response } = await axiosApiClient.delete(url)
  return response || []
}

export async function getLocationAttributes(
  markets: string[] = [],
  includeInactive = false,
): Promise<LocationAttribute[]> {
  const searchQueries = new URLSearchParams(
    markets.map((market) => ['market', market]),
  )

  searchQueries.append('includeInactive', includeInactive.toString())

  const url = `${BASE_URL}/org/location-attributes?${searchQueries.toString()}`

  const { data } = await axiosApiClient.get(url)

  return data
}

export async function getRoundingRules(): Promise<
  {
    id: string
    org: string
    bid: number
    offer: number
  }[]
> {
  const url = `${BASE_URL}/rounding-criteria`
  const { data: response } = await axiosApiClient.get(url)
  return response
}

export async function updateRoundingRules(
  id: string,
  data: { bid: number; offer: number },
): Promise<{
  id: string
  org: string
  bid: number
  offer: number
}> {
  const url = `${BASE_URL}/rounding-criteria/${id}`
  const { data: response } = await axiosApiClient.patch(url, data)
  return response
}

export async function getTargetNetOpenPositions(): Promise<
  TargetNetOpenPositions[]
> {
  const url = `${BASE_URL}/net-open-position/rule`
  const { data: response } = await axiosApiClient.get(url)
  return response
}

export async function updateTargetNetOpenPositions(
  id: string,
  data: { bid: number; offer: number; location: string },
): Promise<TargetNetOpenPositions> {
  const url = `${BASE_URL}/net-open-position/rule/${id}`
  const { data: response } = await axiosApiClient.patch(url, data)
  return response
}

export async function getPriceInfo(): Promise<PriceInfo[]> {
  const url = `${BASE_URL}/price-info`
  const { data: response } = await axiosApiClient.get(url)
  return response
}

export async function deletePriceInfo(priceInfoId: string): Promise<void> {
  const url = `${BASE_URL}/price-info/${priceInfoId}`
  return axiosApiClient.delete(url)
}

export async function getDefaultPriceInfo(): Promise<DefaultPricingItem[]> {
  const url = `${BASE_URL}/price-info/default`
  const { data: response }: AxiosResponse<DefaultPricingItem[]> =
    await axiosApiClient.get(url)

  return response
}

export async function updateDefaultPriceInfo(
  id: string,
  data: { bidPrice: number; offerPrice: number },
): Promise<TargetNetOpenPositions> {
  const url = `${BASE_URL}/price-info/default/${id}`
  const { data: response } = await axiosApiClient.patch(url, data)
  return response
}

export async function addPriceInfo({
  market,
  brand,
  location,
  intervalStart,
  intervalEnd,
  bidPrice,
  offerPrice,
}): Promise<void> {
  const configs = await getConfigIds()
  if (!configs?.length) {
    return
  }

  const config = configs.find(
    (c) => c.market === market && c.brand === brand && c.isActive,
  )
  if (!config) {
    return
  }

  const url = `${BASE_URL}/price-info/${config?.configId}`
  const { data: response } = await axiosApiClient.post(url, {
    location,
    intervalStart,
    intervalEnd,
    bidPrice,
    offerPrice,
  })

  return response
}

export async function updatePriceInfo({
  priceInfoId,
  intervalStart,
  intervalEnd,
  bidPrice,
  offerPrice,
}): Promise<void> {
  const url = `${BASE_URL}/price-info/${priceInfoId}`
  const { data: response } = await axiosApiClient.patch(url, {
    intervalStart,
    intervalEnd,
    bidPrice,
    offerPrice,
  })

  return response
}

export async function submitToISO(data: DayAheadDemandBid[]) {
  const groupedByMarketAndBrand = groupBy(
    data,
    ({ market, brand }) => `${market}-${brand}`,
  )

  return Promise.allSettled(
    Object.entries(groupedByMarketAndBrand).map(async ([key, value]) => {
      const [market] = key.split('-')

      const url = `${BASE_URL}/day-ahead/demand-bids/${market}/submit`

      const submissionData = value.map((v) => ({
        brand: v.brand,
        date: v.date,
        zone: v.zone,
        location: v.location,
        intervals: v.intervals,
      }))

      const { data: response }: AxiosResponse<string> =
        await axiosApiClient.post(url, submissionData)

      return response
    }),
  )
}

export async function submitIbtsToISO(data: InternalBilateral[]) {
  const groupedByMarketBrandContractId = groupBy(
    data,
    ({ market, brand, zone, buyer_qse_code, seller_qse_code, contract_name }) =>
      `${market}-|-${brand}-|-${zone}-|-${buyer_qse_code}-|-${seller_qse_code}-|-${contract_name}`,
  )

  return Promise.allSettled(
    Object.entries(groupedByMarketBrandContractId).map(async ([key, value]) => {
      const [market, brand, zone, buyerQseCode, sellerQseCode, contractName] =
        key.split('-|-')

      const url = `${BASE_URL}/physical-scheduling/${market}/submit`

      const submissionData = value.map((v) => ({
        contractName,
        market,
        brand,
        zone,
        buyerQseCode,
        sellerQseCode,
        date: v.date,
        intervals: v.intervals,
      }))

      const { data: response }: AxiosResponse<string> =
        await axiosApiClient.post(url, submissionData)

      return response
    }),
  )
}

export async function getSubmittableDatesForAllMarkets(): Promise<
  SubmittableDates | undefined
> {
  try {
    const url = `${BASE_URL}/utils/submittable-dates`
    const { data: response } = await axiosApiClient.get(url)
    const { data: responseData } = response
    return responseData
  } catch (error) {
    if (error?.isAxiosError) {
      const { response } = error
      const { data: responseData } = response

      if ('code' in responseData && 'data' in responseData) {
        return responseData.data
      }
      return responseData
    }
  }
}

export async function getPodData(datasource: string, timezone = 'UTC') {
  const podMetadata = await getDatasourceMetadata(datasource)
  const columns = Object.keys(podMetadata.columns)
  const minDate = podMetadata.maxTime.startOf('day')
  const maxDate = podMetadata.maxTime.endOf('day')

  const startDate = DateTime.now().setZone(timezone).plus({ day: 1 })
  const endDate = DateTime.now().setZone(timezone).plus({ day: 7 })

  return getRawData({
    datasource,
    pageSize: 0,
    pageNumber: 10000,
    columns,
    filter: {
      type: 'and',
      fields: [
        {
          type: 'bound',
          dimension: 'VIRTUAL_start_time',
          lower: startDate.startOf('day').toMillis(),
          lowerStrict: false,
          upper: endDate.endOf('day').toMillis(),
          upperStrict: false,
          ordering: {
            type: 'numeric',
          },
        },
      ],
    },
    virtualColumns: [
      {
        type: 'expression',
        name: 'VIRTUAL_start_time',
        expression: `timestamp_parse("start_time",null,'${timezone}')`,
        outputType: 'LONG',
      },
    ],
    intervals: [`${minDate}/${maxDate}`],
    sort: 'ascending',
  })
}

export async function getSubmissionHistoryColumns() {
  const url = `${BASE_URL}/submissions/scan`
  const { data: response } = await axiosApiClient.get(url)

  return response || []
}

export async function getSubmissionMetas(queries: Record<string, string> = {}) {
  const queryString = new URLSearchParams(Object.entries(queries)).toString()
  const url = `${BASE_URL}/submissions/meta?${queryString}`

  const { data: response } = await axiosApiClient.get(url)

  return response || []
}

export async function getSubmissionHistory(
  queries: Record<string, string> = {},
): Promise<SubmissionHistory[]> {
  const queryString = new URLSearchParams(Object.entries(queries)).toString()
  const url = `${BASE_URL}/submissions?${queryString}`

  const { data: response } = await axiosApiClient.get(url)

  return response || []
}

export async function getSubmissionHistoryBySubmissionGroupId(
  submissionGroupId: string,
  filters: Record<string, string | null>,
): Promise<SubmissionHistory[]> {
  const queryString = new URLSearchParams({
    submissionGroupId,
    ...filters,
  }).toString()
  const url = `${BASE_URL}/submissions?${queryString}`

  const { data: response } = await axiosApiClient.get(url)

  return response || []
}

export async function submitToErcotISO(data: ErcotLSSubmissionData[]) {
  const groupedByMarketAndBrand = groupBy(data, ({ brand }) => `ERCOT-${brand}`)

  return Promise.allSettled(
    Object.entries(groupedByMarketAndBrand).map(async ([key, value]) => {
      const [market] = key.split('-')

      const url = `${BASE_URL}/day-ahead/bids-offers/${market}/submit`

      const submissionData = value.map((v) => ({
        brand: v.brand,
        date: v.date,
        zone: v.zone,
        location: v.location,
        intervals: v.intervals,
      }))

      const { data: response }: AxiosResponse<string> =
        await axiosApiClient.post(url, submissionData)

      return response
    }),
  )
}

type PricingItem = {
  market: string
  location: string
  brand: string
  org: string
  intervalStart: number
  intervalEnd: number
  bidPrice: string
  offerPrice: string
}

type DefaultPricingItem = {
  priceInfoId: string
  market: string
  org: string
  bidPrice: string
  offerPrice: string
}

type PricingConfig = {
  [key: string]: {
    [key: string]: {
      bidPrice: string
      offerPrice: string
    }
  }
}

export async function getPricingInfo(): Promise<PricingConfig> {
  const pricingUrl = `${BASE_URL}/price-info`

  const { data: pricingData }: AxiosResponse<PricingItem[]> =
    await axiosApiClient.get(pricingUrl)

  pricingData.sort((a, b) => a.intervalStart - b.intervalStart)

  const resultObject = pricingData.reduce((memo, current) => {
    const key = `${current.market}_|_${current.location}_|_${current.brand}`

    for (let i = current.intervalStart; i <= current.intervalEnd; i++) {
      memo[key] = {
        ...memo[key],
        [`${i}`]: {
          bidPrice: current.bidPrice,
          offerPrice: current.offerPrice,
        },
      }
    }
    return memo
  }, {})

  return resultObject
}

// FIXME: Replace with getRoundingRules
export async function getRoundingInfo(): Promise<RoundingItemResult> {
  const roundingUrl = `${BASE_URL}/rounding-criteria`

  const { data: roundingData }: AxiosResponse<RoundingItem[]> =
    await axiosApiClient.get(roundingUrl)

  return {
    ...roundingData[0],
    bid: number(roundingData[0].bid),
    offer: number(roundingData[0].offer),
  }
}

export async function getExpandedTradesPodData(
  datasource: string,
  timezone: string,
): Promise<{ count: number; events: ExpandedTradesPodData[] }> {
  const podMetadata = await getDatasourceMetadata(datasource)
  const columns = Object.keys(podMetadata.columns)
  const minDate = podMetadata.maxTime.startOf('day')
  const maxDate = podMetadata.maxTime.endOf('day')

  const startDate = DateTime.now().setZone(timezone).plus({ day: 1 })
  const endDate = DateTime.now().setZone(timezone).plus({ month: 1 })

  const { count, events } = await getRawData({
    datasource,
    pageSize: 0,
    pageNumber: 10000,
    columns,
    filter: {
      type: 'and',
      fields: [
        {
          type: 'bound',
          dimension: 'VIRTUAL_start_time',
          lower: startDate.startOf('day').toMillis(),
          lowerStrict: false,
          upper: endDate.endOf('day').toMillis(),
          upperStrict: false,
          ordering: {
            type: 'numeric',
          },
        },
      ],
    },
    virtualColumns: [
      {
        type: 'expression',
        name: 'VIRTUAL_start_time',
        expression: `timestamp_parse("start_time",null,'${timezone}')`,
        outputType: 'LONG',
      },
    ],
    intervals: [`${minDate}/${maxDate}`],
    sort: 'ascending',
  })

  return { count, events }
}

export async function getInternalBilateralContracts(): Promise<
  InternalBilateralContract[]
> {
  const url = `${BASE_URL}/physical-scheduling/contracts?market=ercot&market=pjm`

  const { data: response }: AxiosResponse<InternalBilateralContract[]> =
    await axiosApiClient.get(url)

  return response || []
}

export async function updateErcotSubmitStatuses(submittedDate: string) {
  const url = `${BASE_URL}/day-ahead/bids-offers/ercot/update-status/${submittedDate}`

  await axiosApiClient.post(url)
}

export const getLSSubmissionsList = async (
  page = 0,
  pageSize = 5,
  searchValue = '',
): Promise<{ total: number; rows: LSSubmissionSummary[] }> => {
  const filterString = new URLSearchParams({
    skip: page.toString(),
    limit: pageSize.toString(),
    sortDirection: 'desc',
    sortColumn: 'created_at',
    searchValue,
  }).toString()
  const url = `/pods/v1/alerts?${filterString}`
  const { data } = await axiosApiClient.get(url)
  return data as { total: number; rows: LSSubmissionSummary[] }
}

export async function getClientClearedBids({
  startDate,
  endDate,
  market,
  brand,
  zone,
  locationName,
  location,
  submitType,
}: {
  startDate: string
  endDate: string
  market?: string
  brand?: string
  zone?: string
  locationName?: string
  location?: string
  submitType?: string
}): Promise<ClearedBidDTO[]> {
  const params = new URLSearchParams({
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(market && { market }),
    ...(brand && { brand }),
    ...(zone && { zone }),
    ...(locationName && { locationName }),
    ...(location && { location }),
    ...(submitType && { submitType }),
  })

  const url = `${BASE_URL}/cleared-bids?${params.toString()}`
  const { data: response } = await axiosApiClient.get(url)
  return response
}

export async function getClearedBidsFilters(): Promise<
  Record<string, string[]>
> {
  const url = `${BASE_URL}/cleared-bids/scan`
  const { data: response } = await axiosApiClient.get(url)
  return response
}
