import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router'
import UserContext from 'shared/contexts/UserContext'
import ClearedBidsHistory from './pages/ClearedBidsHistory'
import IBTSubmit from './pages/IBTSubmit'
import LoadSchedulingSubmit from './pages/LoadSchedulingSubmit'
import LoadSchedulingSubmissionHistory from './pages/SubmissionHistory'
import SubmissionHistoryDetailsPage from './pages/SubmissionHistoryDetails'

const LoadSchedulingWrapper = () => {
  const { availableTools } = useContext(UserContext)
  const isAuthorised = availableTools.includes('loadScheduling')

  return isAuthorised ? <Outlet /> : <Navigate to="/not-authorised" replace />
}

export const routes = [
  {
    title: 'Load Scheduling',
    key: 'load-scheduling',
    path: '/load-scheduling',
    main: <LoadSchedulingWrapper />,
    childRoutes: [
      {
        title: 'Load Scheduling Submit',
        key: 'ls-submit',
        main: <LoadSchedulingSubmit />,
        path: '/submit',
        entryPoint: true,
      },
      {
        title: 'Load Scheduling IBT',
        key: 'ls-ibt-submit',
        main: <IBTSubmit />,
        path: '/ibt',
      },
      {
        title: 'Load Scheduling Cleared Bids',
        key: 'ls-cleared-bids',
        main: <ClearedBidsHistory />,
        path: '/cleared-bids',
      },
    ],
  },
  {
    title: 'Load Scheduling',
    key: 'load-scheduling',
    path: '/load-scheduling',
    main: <LoadSchedulingWrapper />,
    childRoutes: [
      {
        title: 'LS Submission History',
        key: 'ls-submission-history',
        main: <LoadSchedulingSubmissionHistory />,
        path: '/submission-history',
        entryPoint: true,
      },
      {
        title: 'LS Submission History Details',
        key: 'ls-submission-history-details',
        main: <SubmissionHistoryDetailsPage />,
        path: '/submission-history/:market/details/:submissionGroupId',
      },
    ],
  },
]
