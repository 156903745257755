import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWDropdown, {
  type DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import IWRelativeDatePickerWrapper from 'shared/components/thunderbolt/IWRelativeDatePickerWrapper'
import IWSpacer from 'shared/components/thunderbolt/IWSpacer'
import LayoutContext from 'shared/contexts/LayoutContext'
import { convertToCVS, downloadContent } from 'shared/helpers'
import {
  getSubmissionHistoryBySubmissionGroupId,
  getSubmissionHistoryColumns,
  getSubmissionMetas,
} from 'shared/loadSchedulingClient'
import styled from 'styled-components'
import LoadSchedulingTable, {
  EmptyMessage,
  type LsColumnIsInView,
} from '../components/LSTable'
import { useSubmissionHistoryFilters } from '../hooks/useSubmissionHistoryFilters'
import { getLoadSchedulingSubmissionsSchema } from '../schemas'
import type { LSSubmissionHistoryTableRow, TSubmissionMetas } from '../types'

const StyledWrapper = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`

const StyledIWButton = styled(IWButton)`
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
`

const StyledFilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`

const hiddenColumns = ['submissionGroupId']

const SubmissionHistory = () => {
  const { t } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [tableItems, setTableItems] = useState<LSSubmissionHistoryTableRow[]>(
    [],
  )

  const tableColumns = getLoadSchedulingSubmissionsSchema()

  const initialColumns = tableColumns.reduce((memo, current) => {
    return {
      ...memo,
      [current.accessor]: {
        ...current,
        isInView: hiddenColumns.includes(current.accessor) ? false : true,
        disableToggle:
          current.accessor === 'viewMore' || current.accessor === 'download',
      },
    }
  }, {} as LsColumnIsInView)

  const defaultDropdownOption = { label: 'All', value: 'all' }
  const {
    populateFilters,
    handleOnSelect,
    getFiltersValues,
    marketOptions,
    brandOptions,
    locationOptions,
    submittedByOptions,
    submissionStatusOptions,
    scheduleTypeOptions,
    setSubmittedDatePickerIsOpen,
    setDateDatePickerIsOpen,
    selectedMarket,
    selectedBrand,
    selectedLocation,
    selectedSubmittedBy,
    selectedSubmissionStatus,
    selectedScheduleType,
    isSubmittedDatePickerOpen,
    isDateDatePickerOpen,
    submittedDatePickerState,
    dateDatePickerState,
    handleSubmittedDatePickerCancel,
    handleDateDatePickerCancel,
    handleSubmittedDatePickerConfirm,
    handleDateDatePickerConfirm,
  } = useSubmissionHistoryFilters()

  const convertToDownloadFormat = async ({
    submissionGroupId,
    market,
    brand,
    zone,
    location,
  }: {
    submissionGroupId: string
    market: string
    brand: string
    zone: string
    location: string
  }) => {
    const submissions = await getSubmissionHistoryBySubmissionGroupId(
      submissionGroupId,
      { market, brand, zone, location },
    )

    const csvContent = submissions.map((item) => {
      const row = {
        submission_id: item.submissionId,
        group_id: submissionGroupId,
        org: item.org,
        market: item.market,
        brand: item.brand,
        zone: item.zone,
        date: item.date,
        location: item.location,
        location_name: item.locationName,
        message: item.message || 'N/A',
        mrid: item.mrid || 'N/A',
        bid_id: item.bidId || 'N/A',
        submittedBy: item.submittedBy,
        submit_status: item.submitStatus,
        schedulingType: item.schedulingType,
        status: item.latestSubmissionStatus,
        log: item.submissionLog || 'N/A',
        was_successful: item.wasSuccessful,
      }

      for (const interval of item.intervals) {
        const hour = interval.interval
        row[hour] = interval.load

        if (interval.price !== undefined) {
          row[`${hour}_price`] = interval.price
        }
        if (interval.submitType !== undefined) {
          row[`${hour}_submit_type`] = interval.submitType
        }
        if (interval.curveType !== undefined) {
          row[`${hour}_curve_type`] = interval.curveType
        }
      }

      return row
    })

    if (csvContent.length === 0) {
      return
    }

    const filename = `ls-history-${market}-${brand}-${zone}-${location}`
    const csv = convertToCVS(csvContent)

    downloadContent({
      filename,
      ext: 'csv',
      content: csv,
    })
  }

  const tableMapper = (
    submissions: TSubmissionMetas[],
  ): LSSubmissionHistoryTableRow[] => {
    if (submissions.length === 0) {
      return []
    }
    const transformed = submissions.map((value) => {
      const {
        market,
        brand,
        location,
        zone,
        createdAt,
        submissionGroupId,
        startFlowDate,
        endFlowDate,
        submissionStatus,
        isoStatus,
      } = value

      const minDate =
        DateTime.min(DateTime.fromISO(startFlowDate.toString())).toISODate() ||
        ''
      const maxDate =
        DateTime.max(DateTime.fromISO(endFlowDate.toString())).toISODate() || ''

      const formattedDate = DateTime.fromISO(createdAt as string, {
        zone: timezone,
      })

      const schedulingType =
        value.schedulingType === 'PHYSICAL_SCHEDULING'
          ? t('loadScheduling.physicalType')
          : t('loadScheduling.demandType')

      return {
        market,
        brand,
        zone,
        location,
        schedulingType,
        startFlowDate: minDate,
        endFlowDate: maxDate,
        data: value,
        submittedDate: formattedDate,
        submissionGroupId,
        latestSubmissionStatus: (
          <div style={{ display: 'inline-block' }}>
            {submissionStatus === 'completedSubmission' ? (
              <IWGeneralBadge
                label={
                  market === 'ERCOT' && isoStatus === 'CANCELED'
                    ? t('loadScheduling.cancelled')
                    : market === 'ERCOT' && isoStatus === 'ACCEPTED'
                    ? t('loadScheduling.accepted')
                    : market === 'ERCOT' && isoStatus === 'SUBMITTED'
                    ? t('loadScheduling.submitted')
                    : t('loadScheduling.completed')
                }
                color={
                  market === 'ERCOT' && isoStatus === 'CANCELED'
                    ? 'warning'
                    : 'secondary'
                }
              />
            ) : submissionStatus === 'failedSubmission' ? (
              <IWGeneralBadge
                label={t('loadScheduling.failed')}
                color="alert"
              />
            ) : submissionStatus === 'processingSubmission' ? (
              <IWGeneralBadge
                label={t('loadScheduling.processing')}
                color="warning"
              />
            ) : submissionStatus === 'partialSubmission' ? (
              <IWGeneralBadge
                label={t('loadScheduling.partial')}
                color="alert"
              />
            ) : (
              <IWGeneralBadge
                label={t('loadScheduling.unknown')}
                color="alert"
              />
            )}
          </div>
        ),
        download: (
          <div style={{ display: '' }}>
            <IWButton
              variant="anchorMain"
              disabled={submissionStatus !== 'completedSubmission'}
              onClick={() =>
                convertToDownloadFormat({
                  submissionGroupId,
                  market,
                  brand,
                  zone,
                  location,
                })
              }
            >
              {t('button.download')}
            </IWButton>
          </div>
        ),
        viewMore: (
          <div style={{ display: '' }}>
            <IWButton
              variant="anchorMain"
              disabled={submissionStatus === 'processingSubmission'}
              onClick={() => {
                navigate(
                  `/tools/load-scheduling/submission-history/${market}/details/${submissionGroupId}?market=${market}&brand=${brand}&location=${location}&zone=${zone}`,
                )
              }}
            >
              {t('button.viewMore')}
            </IWButton>
          </div>
        ),
      }
    })

    transformed.sort(
      (a, b) => b.submittedDate.valueOf() - a.submittedDate.valueOf(),
    )
    return transformed.map((item) => ({
      ...item,
      submittedDate: `${item.submittedDate.toLocaleString(
        DateTime.DATETIME_SHORT,
      )} ${item.submittedDate.toFormat('ZZZZ')}`,
    }))
  }

  const { data: filters, isLoading: isLoadingColumns } = useQuery(
    ['filters'],
    getSubmissionHistoryColumns,
    {
      staleTime: 1000 * 5,
      enabled: true,
    },
  )

  const onSearch = async () => {
    setIsLoading(true)

    const queryFilters = getFiltersValues()
    const submissions = await getSubmissionMetas(queryFilters)
    const mappedSubmissions = tableMapper(submissions)

    setTableItems(mappedSubmissions)
    setIsLoading(false)
  }

  const renderFilters = () => {
    return (
      <StyledFilterWrapper>
        <IWDropdown
          fullWidth
          label={t('loadScheduling.submissionHistoryOptions.labels.market')}
          options={marketOptions}
          defaultValue={defaultDropdownOption}
          value={selectedMarket}
          onChange={(value: DropdownValueProps) => {
            handleOnSelect('market', value)
          }}
        />
        <IWDropdown
          fullWidth
          label={t('loadScheduling.submissionHistoryOptions.labels.brand')}
          options={brandOptions}
          defaultValue={defaultDropdownOption}
          value={selectedBrand}
          onChange={(value: DropdownValueProps) => {
            handleOnSelect('brand', value)
          }}
        />
        <IWDropdown
          fullWidth
          label={t('loadScheduling.submissionHistoryOptions.labels.location')}
          options={locationOptions}
          defaultValue={defaultDropdownOption}
          value={selectedLocation}
          onChange={(value: DropdownValueProps) => {
            handleOnSelect('location', value)
          }}
        />

        <IWDropdown
          fullWidth
          label={t(
            'loadScheduling.submissionHistoryOptions.labels.submittedBy',
          )}
          options={submittedByOptions}
          defaultValue={defaultDropdownOption}
          value={selectedSubmittedBy}
          onChange={(value: DropdownValueProps) => {
            handleOnSelect('submittedBy', value)
          }}
        />

        <IWRelativeDatePickerWrapper
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dateDatePickerState}
          label={t('loadScheduling.submissionHistoryOptions.labels.date')}
          maxTimeLengthValue={100}
          isOpen={isDateDatePickerOpen}
          onPlaceholderClick={() => setDateDatePickerIsOpen(true)}
          onConfirm={() => {
            handleDateDatePickerConfirm()
            onSearch()
          }}
          onCancel={handleDateDatePickerCancel}
        />

        <IWRelativeDatePickerWrapper
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...submittedDatePickerState}
          label={t(
            'loadScheduling.submissionHistoryOptions.labels.submittedDate',
          )}
          maxTimeLengthValue={100}
          isOpen={isSubmittedDatePickerOpen}
          onPlaceholderClick={() => setSubmittedDatePickerIsOpen(true)}
          onConfirm={() => {
            handleSubmittedDatePickerConfirm()
            onSearch()
          }}
          onCancel={handleSubmittedDatePickerCancel}
        />

        <IWDropdown
          fullWidth
          label={t('loadScheduling.schedulingType')}
          options={scheduleTypeOptions}
          defaultValue={defaultDropdownOption}
          value={selectedScheduleType}
          onChange={(value: DropdownValueProps) => {
            handleOnSelect('scheduleType', value)
          }}
        />

        <IWDropdown
          fullWidth
          label={t('loadScheduling.submissionHistoryOptions.labels.status')}
          options={submissionStatusOptions}
          defaultValue={defaultDropdownOption}
          value={selectedSubmissionStatus}
          onChange={(value: DropdownValueProps) => {
            handleOnSelect('submissionStatus', value)
          }}
        />

        <StyledIWButton onClick={onSearch} disabled={isLoading}>
          {t('button.search')}
        </StyledIWButton>
      </StyledFilterWrapper>
    )
  }

  useEffect(() => {
    if (filters !== undefined) {
      populateFilters(filters)
      onSearch()
    }
  }, [filters])

  useEffect(() => {
    if (filters === undefined) {
      return
    }

    onSearch()
  }, [
    selectedBrand?.value,
    selectedLocation?.value,
    selectedSubmittedBy?.value,
    selectedSubmissionStatus?.value,
    selectedMarket?.value,
    selectedScheduleType?.value,
  ])

  if (isLoadingColumns) {
    return <IWLoading />
  }

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <StyledWrapper>
          <StyledTitle>{t('loadScheduling.submissionHistory')}</StyledTitle>
          <IWSpacer />
        </StyledWrapper>
      </IWPageHeader>

      <main>
        {renderFilters()}

        {isLoading && <IWLoading />}
        {!isLoading && !Boolean(tableItems.length) && <EmptyMessage />}
        {!isLoading && Boolean(tableItems.length) && (
          <LoadSchedulingTable
            columns={tableColumns}
            items={tableItems}
            isLoading={isLoading}
            initialColumns={initialColumns}
            hasColumnSettings
          />
        )}
      </main>
    </>
  )
}

export default SubmissionHistory
